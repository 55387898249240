var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SearchTable", {
    attrs: {
      name: "calls",
      title: "Звонки",
      columns: _vm.columns,
      dynamic: "",
      quickSearch: "dst",
      apiTopic: "phone",
      apiPath: "/calls?paged=1",
      metaApiTopic: "phone",
      metaApiPath: "/meta/calls/search"
    },
    scopedSlots: _vm._u([
      {
        key: "calldate",
        fn: function({ rec }) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(rec.calldate) +
                " -- " +
                _vm._s(rec.disposition) +
                "\n  "
            )
          ]
        }
      },
      {
        key: "dst",
        fn: function({ rec }) {
          return [
            _c(
              "span",
              { attrs: { title: _vm.dstTitle(rec) } },
              [
                _vm.isIngoing(rec)
                  ? [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-import",
                        attrs: { title: "Входящий", "aria-hidden": "true" }
                      }),
                      _vm._v("\n      " + _vm._s(rec.src) + "\n    ")
                    ]
                  : [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-export",
                        attrs: { title: "Исходящий", "aria-hidden": "true" }
                      }),
                      _vm._v("\n      " + _vm._s(rec.dst) + "\n    ")
                    ]
              ],
              2
            )
          ]
        }
      },
      {
        key: "billsec",
        fn: function({ rec }) {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.common.formatUptime(rec.billsec)) + "\n  "
            )
          ]
        }
      },
      {
        key: "record",
        fn: function({ rec }) {
          return [
            rec.has_record
              ? _c(
                  "span",
                  { staticClass: "text-center", attrs: { title: "Активен" } },
                  [_c("PhoneCall", { attrs: { record: rec.uniqueid } })],
                  1
                )
              : _c(
                  "span",
                  {
                    staticClass: "text-center text-muted",
                    attrs: { title: "Запись удалена" }
                  },
                  [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-minus",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                )
          ]
        }
      },
      {
        key: "worker",
        fn: function({ rec }) {
          return [
            rec.worker && rec.worker != "unknown"
              ? _c("a", { attrs: { href: "'/worker/'+rec.worker" } }, [
                  _vm._v(_vm._s(rec.worker))
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }